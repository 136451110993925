/* eslint-disable import/no-webpack-loader-syntax */
import React, { useState, useContext, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { Link } from '@ths/design-system';
import { ReferIcon } from '@ths/design-system/icons';
import { track, Events } from 'utils/analytics';
import ZIndexManager from 'components/ZIndexManager';
import NavLink from 'components/NavLink';
import LoginLinkWrapper from 'components/LoginLinkWrapper';
import { MenuItems } from 'config/links';
import RegwallMessaging from 'components/Regwall/Regwall.constants';
import RegwallDialog, { OpenMethod } from 'components/RegwallDialog';
import { routes } from 'utils/routes';
import { useExperimentalFeature } from 'hooks/useExperimentalFeature';
import { PlanTiers } from 'api/types/plans';
import ExperimentalFeature, {
    experiments,
    VariationTypes,
    userTypes,
    trackExperimentalFeature,
    UTM_SOURCES,
} from 'containers/ExperimentalFeature';
import { IconClose, IconMenu } from 'components/Icon';
import useHideFavouritesCheck, { useSeeFavouritesAnalytics } from 'hooks/useHideFavouritesCheck';
import { isFeatureEnabled, features } from 'components/Feature';
import { useSelector } from 'react-redux';
import { getAcqExperiments } from 'src/shared/acqCampaign';
import { getAccountReferFreeMonths } from 'api/selectors/account';

import { isUserInAcqCampaign } from 'src/shared/acqCampaign/utils';
import {
    Container,
    LogoLinkStyled,
    Left,
    Right,
    MenuButton,
    MobileMenuWrapper,
    AccountMenuStyled,
    JoinNowButtonStyled,
    LeftHandSideNavLinkStyled,
    NavLinkJoinNowStyled,
    NavLinkJoinNowDesktopStyled,
    NavLinkJoinNowMobileStyled,
} from './Navbar.style';
import { MembershipStatus, MembershipType } from './Navbar.constants';
import NavMenu from './components/NavMenu';
import Logo from './components/Logo';

export const NavbarTestIds = {
    navbar: 'Navbar_navbar',
    findASitter: 'nav-link-find-a-sitter',
    findASit: 'nav-link-find-a-sit',
    meetOurSitters: 'nav-link-meet-our-sitters',
    referAFriend: 'nav-link-refer-a-friend',
    upgrade: 'nav-link-upgrade',
    partialJoinNow: 'partial-join-now',
    expiredMemberRenew: 'expired-member-renew',
    regwallDialog: 'regwall-dialog',
};

const LoginRedirect = ({ membershipStatus }) =>
    membershipStatus === MembershipStatus.MEMBER ? (
        <Redirect to={routes.accounts.profile.dashboard()} />
    ) : (
            <Redirect to={routes.accounts.explorePlans()} />
        );

const trackHowItWorksClick = () => {
    track(
        Events.BUTTONCLICK.create({
            description: 'How it works nav bar link',
        })
    );
};

const trackLoginClick = () => {
    track(
        Events.BUTTONCLICK.create({
            description: 'Log in nav bar link',
        })
    );
};

const Navbar = ({
    isLoggedIn,
    avatarPublicId,
    userName,
    membershipStatus,
    membershipTier,
    membershipType,
    isOnFreeTrial,
    isOwnerFunnelExp,
    sitterHomepageVariation,
    isSitterOnly,
    isPaidMember,
    shouldFireAnalyticsForSitterHomepage,
    options = {},
    isNoWizardNavVariation,
}) => {
    const { t } = useTranslation();
    const acqCampaignTests = useSelector(getAcqExperiments);
    const freeMonths = useSelector(getAccountReferFreeMonths);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isRegwallVisible, setRegwallVisible] = useState(false);
    const isNotPremiumAndCombined = !(
        membershipTier === PlanTiers.PREMIUM && membershipType === MembershipType.COMBINED
    );
    const [isTracked, setIsTracked] = useState(false);
    const shouldHideFavouritesTest = useHideFavouritesCheck();
    const [shouldSeeFavouritesAnalytics, favouriteTestVariation] = useSeeFavouritesAnalytics();
    const [isFavouriteTestTracked, setIsFavouriteTestTracked] = useState(false);
    const [homePageCTAVariation] = useExperimentalFeature(experiments.HOMEPAGE_CTA);
    const [noWizardNavbarV2Variant] = useExperimentalFeature(experiments.NO_WIZARD_NAVBAR_V2, {
        hasPaidTestConflict: isUserInAcqCampaign(acqCampaignTests),
    });

    const findPetSitterMenuItem = () => {
        const isOnHomepageCTATest = homePageCTAVariation !== VariationTypes.CONTROL;

        if (!isLoggedIn) {
            if (noWizardNavbarV2Variant === VariationTypes.VARIATION1) {
                return MenuItems.searchSittersWithRegwall;
            }

            if (!isNoWizardNavVariation) {
                return isOnHomepageCTATest
                    ? MenuItems.findASitterWizardHomepageCTA
                    : MenuItems.findASitterWizard;
            }
        }

        return isOnHomepageCTATest && !isLoggedIn
            ? MenuItems.searchSittersHomepageCTA
            : MenuItems.searchSitters;
    };

    const findAHouseSitMenuItem = () => {
        if (!isLoggedIn) {
            if (noWizardNavbarV2Variant === VariationTypes.VARIATION1) {
                return MenuItems.searchSitsWithRegwall;
            }

            if (!isNoWizardNavVariation) {
                return MenuItems.findAHouseSitWizard;
            }
        }

        return MenuItems.searchSits;
    };

    useEffect(() => {
        if (isSitterOnly && isPaidMember && shouldFireAnalyticsForSitterHomepage && !isTracked) {
            trackExperimentalFeature(
                experiments.SITTER_HOMEPAGE.name,
                sitterHomepageVariation,
                'sitter homepage test analytics'
            );

            setIsTracked(true);
        }
        if (!isFavouriteTestTracked && shouldSeeFavouritesAnalytics) {
            trackExperimentalFeature(
                features.HIDE_FAVOURITES,
                favouriteTestVariation,
                'hide favourites test analytics'
            );
            setIsFavouriteTestTracked(true);
        }
    }, [
        shouldFireAnalyticsForSitterHomepage,
        sitterHomepageVariation,
        isTracked,
        isPaidMember,
        isSitterOnly,
        isFavouriteTestTracked,
        shouldSeeFavouritesAnalytics,
        favouriteTestVariation,
    ]);

    const isUpgradePageEnabled = isFeatureEnabled({ name: features.UPGRADE_LANDING_PAGE });
    const isSitterHomepageVariation = sitterHomepageVariation === VariationTypes.VARIATION1;
    const showHomePageVariation =
        isSitterHomepageVariation && isSitterOnly && membershipStatus === MembershipStatus.MEMBER;
    const toggleMenu = () => setMenuOpen(!isMenuOpen);
    const closeMenu = () => setMenuOpen(false);

    const onClickLogo = () => {
        track(
            Events.LINKCLICK.create({
                description: 'THS Logo nav bar',
            })
        );
        closeMenu();
    };

    const referAFriendClicked = () => {
        track(Events.BUTTONCLICK.create({ description: 'RAF Nav Bar CTA' }));
    };

    const upgradeButtonCLicked = () => {
        track(Events.BUTTONCLICK.create({ description: 'Upgrade Nav Bar CTA' }));
    };

    const helpNavLinkClicked = () => {
        track(Events.BUTTONCLICK.create({ description: 'Help Nav Bar link CTA' }));
    };

    const theme = useContext(ThemeContext);

    const JoinNowCTA = () => (
        <>
            <NavLinkJoinNowDesktopStyled
                cta
                menuItem={{
                    ...MenuItems.joinNowVariation,
                    url: routes.accounts.explorePlans(),
                }}
                data-testid={NavbarTestIds.partialJoinNow}
            />

            <NavLinkJoinNowMobileStyled
                cta
                menuItem={{
                    ...MenuItems.joinNowVariationShort,
                    url: routes.accounts.explorePlans(),
                }}
                data-testid={NavbarTestIds.partialJoinNow}
            />
        </>
    );

    return (
        <ZIndexManager layer="topnav">
            <>
                <MobileMenuWrapper menuOpen={isMenuOpen}>
                    <NavMenu
                        onCloseHandler={closeMenu}
                        t={t}
                        isSitterOnly={isSitterOnly}
                        isPaidMember={isPaidMember}
                        isLoggedIn={isLoggedIn}
                        avatarPublicId={avatarPublicId}
                        userName={userName}
                        membershipStatus={membershipStatus}
                        membershipTier={membershipTier}
                        membershipType={membershipType}
                        isOwnerFunnelExp={isOwnerFunnelExp}
                        isSitterHomepageVariation={isSitterHomepageVariation}
                        isNoWizardNavVariation={isNoWizardNavVariation}
                        showUpgradeCTA={isNotPremiumAndCombined}
                    />
                </MobileMenuWrapper>
                <nav data-testid={NavbarTestIds.navbar}>
                    <ExperimentalFeature
                        experiment={experiments.NO_WIZARD_NAV}
                        control={<></>}
                        excludeCombo={[
                            userTypes.PaidUser,
                            userTypes.ExpiredUser,
                            userTypes.PartialUser,
                        ]}
                        variation1={<></>}
                    />
                    <ExperimentalFeature
                        experiment={experiments.NO_WIZARD_NAVBAR_V2}
                        control={<></>}
                        excludeCombo={[
                            userTypes.PaidUser,
                            userTypes.ExpiredUser,
                            userTypes.PartialUser,
                        ]}
                        variation1={<></>}
                        hasPaidTestConflict={isUserInAcqCampaign(acqCampaignTests)}
                    />
                    <Container menuOpen={isMenuOpen}>
                        <Left name="navbar">
                            {options.disableLogoLink ? (
                                <Logo />
                            ) : (
                                    <LogoLinkStyled onClick={onClickLogo} to="/">
                                        <Logo />
                                    </LogoLinkStyled>
                                )}
                            {/* We want to show these links as early as possible */}
                            {/* showHomePageVariation is used to hide the links below for SITTER_HOMEPAGE test */}
                            {!options.hideLeftSide && !showHomePageVariation && (
                                <>
                                    <LeftHandSideNavLinkStyled
                                        onClick={() => {
                                            track(
                                                Events.LINKCLICK.create({
                                                    description:
                                                        homePageCTAVariation ===
                                                            VariationTypes.CONTROL
                                                            ? 'Find a Pet Sitter nav bar link'
                                                            : 'Find pet care nav bar link',
                                                })
                                            );
                                            closeMenu();
                                        }}
                                        breakpoint={theme.screenSize.smallTablet}
                                        menuItem={findPetSitterMenuItem()}
                                        data-testid={NavbarTestIds.findASitter}
                                    />
                                    <LeftHandSideNavLinkStyled
                                        onClick={() => {
                                            track(
                                                Events.LINKCLICK.create({
                                                    description: 'Find a House Sit Nav bar link',
                                                })
                                            );
                                            closeMenu();
                                        }}
                                        breakpoint={theme.screenSize.smallTablet}
                                        menuItem={findAHouseSitMenuItem()}
                                        data-testid={NavbarTestIds.findASit}
                                    />
                                </>
                            )}
                        </Left>
                        <Right name="navbar">
                            {!options.hideRightSide && (
                                <>
                                    {membershipStatus === MembershipStatus.MEMBER ? (
                                        <>
                                            {isNotPremiumAndCombined && (
                                                <NavLink
                                                    breakpoint={theme.screenSize.largeDesktop}
                                                    menuItem={
                                                        isUpgradePageEnabled
                                                            ? MenuItems.upgradePage
                                                            : MenuItems.upgradeCTA
                                                    }
                                                    onClick={upgradeButtonCLicked}
                                                    data-testid={NavbarTestIds.upgrade}
                                                />
                                            )}
                                        </>
                                    ) : (
                                            <NavLink
                                                breakpoint={theme.screenSize.smallDesktop}
                                                menuItem={MenuItems.howItWorks}
                                                onClick={trackHowItWorksClick}
                                            />
                                        )}

                                    <NavLink
                                        breakpoint={theme.screenSize.smallDesktop}
                                        menuItem={{
                                            ...MenuItems.help,
                                            i18nKey: 'navigation_helpShort',
                                        }}
                                        onClick={helpNavLinkClicked}
                                    />
                                </>
                            )}

                            {isLoggedIn ? (
                                <>
                                    <AccountMenuStyled
                                        avatarPublicId={avatarPublicId}
                                        userName={userName}
                                        isSitterOnly={isSitterOnly}
                                        isPaidMember={isPaidMember}
                                        membershipTier={membershipTier}
                                        membershipType={membershipType}
                                        membershipStatus={membershipStatus}
                                        isLoggedIn={isLoggedIn}
                                        isSitterHomepageVariation={isSitterHomepageVariation}
                                        t={t}
                                        shouldHideFavouritesTest={shouldHideFavouritesTest}
                                        showUpgradeCTA={isNotPremiumAndCombined}
                                    />
                                    {membershipStatus === MembershipStatus.PARTIAL && (
                                        <>
                                            {membershipType === MembershipType.OWNER ? (
                                                <ExperimentalFeature
                                                    experiment={experiments.FREE_TRIAL_OWNER_V2}
                                                    control={<JoinNowCTA />}
                                                    excludeUTMSources={[
                                                        UTM_SOURCES.CJ,
                                                        UTM_SOURCES.IMPACT,
                                                    ]}
                                                    variation1={
                                                        <NavLinkJoinNowStyled
                                                            cta
                                                            menuItem={{
                                                                ...MenuItems.partialOwnerStartFreeTrial,
                                                            }}
                                                            data-testid={
                                                                NavbarTestIds.partialJoinNow
                                                            }
                                                        />
                                                    }
                                                />
                                            ) : (
                                                    <JoinNowCTA />
                                                )}
                                        </>
                                    )}
                                    {membershipStatus === MembershipStatus.MEMBER &&
                                        (!isOnFreeTrial ? (
                                            <Link
                                                className="ml-4 h-8 min-[480px]:h-12"
                                                spanClassName="text-sm min-[480px]:text-md"
                                                isButtonStyle
                                                variant="accent"
                                                leftIcon={
                                                    <ReferIcon className="hidden size-5 min-[480px]:block" />
                                                }
                                                to={routes.accounts.referAFriend()}
                                                onClick={referAFriendClicked}
                                            >
                                                {t('components_navbar_free_months', {
                                                    months: freeMonths,
                                                })}
                                            </Link>
                                        ) : null)}
                                    {membershipStatus === MembershipStatus.EXPIRED && (
                                        <NavLink
                                            cta
                                            breakpoint={theme.screenSize.smallDesktop}
                                            menuItem={{
                                                ...MenuItems.renew,
                                                i18nKey: 'navigation_account_renewShort',
                                            }}
                                            data-testid={NavbarTestIds.expiredMemberRenew}
                                        />
                                    )}
                                </>
                            ) : (
                                    // Not logged in
                                    <>
                                        <LoginLinkWrapper>
                                            {(onClick) => (
                                                <NavLink
                                                    onClick={() => {
                                                        trackLoginClick();
                                                        onClick();
                                                    }}
                                                    accentLink
                                                    breakpoint={theme.screenSize.smallDesktop}
                                                    menuItem={MenuItems.login}
                                                />
                                            )}
                                        </LoginLinkWrapper>
                                        <JoinNowButtonStyled
                                            isMobileHidden={options.hideRightSide}
                                            onClick={() => setRegwallVisible(true)}
                                        >
                                            {t(MenuItems.joinNow.i18nKey)}
                                        </JoinNowButtonStyled>
                                    </>
                                )}
                            <MenuButton
                                aria-label={t('components_navbar_menuButtonAriaLabel')}
                                onClick={toggleMenu}
                                icon={isMenuOpen ? IconClose : IconMenu}
                                iconProps={{
                                    size: 'large',
                                    title: t('components_navbar_menuButtonAriaLabel'),
                                }}
                                isMobileHidden={options.hideRightSide}
                            />
                        </Right>
                    </Container>
                </nav>
                {isRegwallVisible && (
                    <>
                        {isLoggedIn ? (
                            <LoginRedirect membershipStatus={membershipStatus} />
                        ) : (
                                <RegwallDialog
                                    openMethod={OpenMethod.NAV_BAR}
                                    messaging={RegwallMessaging.JOIN}
                                    onCloseHandler={() => setRegwallVisible(false)}
                                    isOpen
                                    t={t}
                                    data-testid={NavbarTestIds.regwallDialog}
                                    membershipType={membershipType}
                                />
                            )}
                    </>
                )}
            </>
        </ZIndexManager>
    );
};

export default withRouter(Navbar);

export { Navbar as BaseNavbar };

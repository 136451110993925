import React, { useState } from 'react';
import { Button } from 'components/buttons';
import RegwallDialog, { OpenMethod } from 'components/RegwallDialog';
import RegwallMessaging from 'components/Regwall/Regwall.constants';
import { routes } from 'utils/routes';
import { Events, track } from 'utils/analytics';
import PropTypes from 'prop-types';
import { MenuSectionStyled, MenuLinkStyled } from '../MenuComponents';

// eslint-disable-next-line no-return-assign
const Redirect = ({ url }) => (window.location = url);

const Menu = ({ menuItems, onCloseHandler, t, isLoggedIn }) => {
    const [regwallVisible, setRegwallVisible] = useState(false);

    const closeHandler = () => {
        onCloseHandler();
        setRegwallVisible(false);
    };

    const handleLinkClicked = ({ linkText }) => {
        track(
            Events.BUTTONCLICK.create({
                description: 'main nav dropdown',
                item: linkText,
            })
        );
        onCloseHandler();
    };

    return (
        <>
            {menuItems.map((menuSection) => (
                <MenuSectionStyled
                    data-testid="menu"
                    removeSectionPartition={menuSection.removeSectionPartition}
                    showAtBreakpoint={menuSection.showAt}
                    hideAtBreakpoint={menuSection.hideAt}
                    key={menuSection.items[0].url}
                >
                    {menuSection.items.map(
                        ({
                            tag,
                            url,
                            accentLink,
                            i18nKey,
                            showNewLabel,
                            launchRegwall,
                            trackLink,
                        }) => {
                            if (launchRegwall) {
                                return (
                                    <MenuLinkStyled
                                        onClick={() => setRegwallVisible(true)}
                                        key="regwall-button"
                                        tag="button"
                                        accentLink={accentLink}
                                        showNewLabel={showNewLabel}
                                        variant={Button.Variant.LINK}
                                        data-testid="regwall-menu-link"
                                    >
                                        {t(i18nKey)}
                                    </MenuLinkStyled>
                                );
                            }
                            return (
                                <MenuLinkStyled
                                    onClick={() => {
                                        if (trackLink) {
                                            track(
                                                Events.LINKCLICK.create({
                                                    description: trackLink,
                                                })
                                            );
                                        }
                                        handleLinkClicked({ linkText: t(i18nKey) });
                                    }}
                                    key={url}
                                    tag={tag}
                                    accentLink={accentLink}
                                    showNewLabel={showNewLabel}
                                    to={url}
                                    data-testid="menu-link"
                                >
                                    {t(i18nKey)}
                                </MenuLinkStyled>
                            );
                        }
                    )}
                </MenuSectionStyled>
            ))}
            {regwallVisible && (
                <>
                    {isLoggedIn ? (
                        <Redirect url={routes.accounts.explorePlans()} />
                    ) : (
                        <RegwallDialog
                            openMethod={OpenMethod.NAV_BAR_MENU}
                            messaging={RegwallMessaging.JOIN}
                            onCloseHandler={closeHandler}
                            isOpen
                            t={t}
                        />
                    )}
                </>
            )}
        </>
    );
};
Menu.propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onCloseHandler: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
};

export default Menu;

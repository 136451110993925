/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import Anchor from 'components/Anchor';
import { Button } from 'components/buttons';
import { colors } from 'theme';
import { NewBadge } from 'components/Badges';

const menuSectionShowAtBreakpoint = css`
    display: none;

    @media screen and (min-width: ${({ showAtBreakpoint }) => showAtBreakpoint}px) {
        display: flex;
    }
`;

const menuSectionHideAtBreakpoint = css`
    @media screen and (min-width: ${({ hideAtBreakpoint }) => hideAtBreakpoint}px) {
        display: none;
    }
`;
const MenuSectionStyled = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => `${theme.spacing[1.5]} 0`};

    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 12px;
        right: 12px;
        bottom: 0;
        height: 1px;
        background-color: ${({ theme }) => theme.palette.border};
    }

    &:last-of-type:after {
        content: none;
    }

    ${({ removeSectionPartition }) =>
        removeSectionPartition &&
        `
        &:after {
            content: none;
        }
    `}
    ${({ removePadding }) =>
        removePadding &&
        `
        padding: 0

    `}

    /* We need to be able to */
    ${({ showAtBreakpoint }) => (showAtBreakpoint ? menuSectionShowAtBreakpoint : null)};
    ${({ hideAtBreakpoint }) => (hideAtBreakpoint ? menuSectionHideAtBreakpoint : null)};
`;

const activeClassName = 'active';
const MenuItemStyles = css`
    display: flex;
    // added to display labels to the far right
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing[1.5]};
    text-decoration: none;
    color: ${({ theme, accentLink }) => (accentLink ? theme.palette.accent : theme.palette.text)};

    font-weight: ${({ theme }) => theme.fontWeights.normal};
    border-radius: 0;
    transition: background-color 100ms ease-in-out;

    &.${activeClassName} {
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        color: ${({ theme }) => theme.palette.black};
    }
    &:hover:enabled,
    &:hover {
        text-decoration: none;
        /* Remove onhover link colours */
        color: inherit;
        background-color: ${colors.white.modifiers.hover.value};
    }
`;

const LabelColStyled = styled.div`
    display: flex;
    align-items: center;
`;

const MenuLinkStyled = styled(({ accentLink, to, showNewLabel, children, tag, ...rest }) => {
    if (tag === 'a') {
        return (
            <Anchor href={to} {...rest}>
                {children}{' '}
                {showNewLabel && (
                    <LabelColStyled>
                        <NewBadge />
                    </LabelColStyled>
                )}
            </Anchor>
        );
    }
    if (tag === 'button') {
        return <Button {...rest}>{children}</Button>;
    }
    return (
        <NavLink exact to={to} {...rest}>
            {children}
        </NavLink>
    );
}).attrs({
    activeClassName,
})`
    ${MenuItemStyles};
`;

export { MenuSectionStyled, MenuLinkStyled };

/* eslint-disable import/prefer-default-export */
import { MenuItems } from 'config/links';
import { routes } from 'utils/routes';

const helpShort = { ...MenuItems.help, i18nKey: 'navigation_helpShort' };

const partialMenuItems = [
    {
        items: [
            {
                ...MenuItems.checkout,
                url: routes.accounts.explorePlans(),
                accentLink: true,
            },
            helpShort,
            MenuItems.forum,
            MenuItems.settings,
        ],
    },
];
const memberHomesitterItems = [
    {
        items: [
            MenuItems.dashboard,
            MenuItems.yourProfile,
            MenuItems.inbox,
            MenuItems.pastSits,
            MenuItems.forum,
            MenuItems.findASitter,
            MenuItems.settings,
        ],
    },
];

const memberHomeownerItems = [
    {
        items: [
            MenuItems.dashboard,
            MenuItems.yourListing,
            MenuItems.inbox,
            MenuItems.favorites,
            MenuItems.pastSitters,
            MenuItems.forum,
            MenuItems.settings,
        ],
    },
];

const memberHomeownerExtraItems = [
    {
        items: [
            MenuItems.dashboard,
            MenuItems.yourListing,
            MenuItems.inbox,
            MenuItems.favorites,
            MenuItems.pastSitters,
            MenuItems.forum,
            MenuItems.settings,
        ],
    },
];

const memberCombinedItems = [
    {
        items: [
            MenuItems.dashboard,
            MenuItems.yourListing,
            MenuItems.yourProfile,
            MenuItems.inbox,
            MenuItems.favorites,
            MenuItems.pastSits,
            MenuItems.pastSitters,
            MenuItems.forum,
            MenuItems.settings,
        ],
    },
];

const memberCombinedExtraItems = [
    {
        items: [
            MenuItems.dashboard,
            MenuItems.yourListing,
            MenuItems.yourProfile,
            MenuItems.inbox,
            MenuItems.favorites,
            MenuItems.pastSits,
            MenuItems.pastSitters,
            MenuItems.forum,
            MenuItems.settings,
        ],
    },
];

const AccountMenuItemsNoFavouritesVariation = {
    partial: {
        housesitter: partialMenuItems,
        homeowner: partialMenuItems,
        combined: partialMenuItems,
    },
    member: {
        housesitter: {
            basic: memberHomesitterItems,
            classic: memberHomesitterItems,
            premium: memberHomesitterItems,
            standard: memberHomesitterItems,
        },
        homeowner: {
            basic: memberHomeownerItems,
            classic: memberHomeownerItems,
            premium: memberHomeownerExtraItems,
            standard: memberHomeownerExtraItems,
        },
        combined: {
            basic: memberCombinedItems,
            classic: memberCombinedItems,
            premium: memberCombinedExtraItems,
            standard: memberCombinedExtraItems,
        },
    },
    expired: {
        housesitter: [
            {
                items: [{ ...MenuItems.renew, accentLink: true }],
            },
            { items: [helpShort, MenuItems.settings] },
        ],
        homeowner: [
            {
                items: [{ ...MenuItems.renew, accentLink: true }],
            },
            { items: [helpShort, MenuItems.settings] },
        ],
        combined: [
            {
                items: [{ ...MenuItems.renew, accentLink: true }],
            },
            { items: [helpShort, MenuItems.settings] },
        ],
    },
};

export { AccountMenuItemsNoFavouritesVariation };

/* eslint-disable import/prefer-default-export */
import { MenuItems } from 'config/links';

const getMobileMenuVariation1 = ({ isAnonymous, isOwnerFunnelExp, theme }) => {
    const helpShort = {
        ...MenuItems.help,
        i18nKey: 'navigation_helpShort',
    };

    function getSearchLinksSection() {
        if (isAnonymous) {
            return [MenuItems.findASitterWizard, MenuItems.findAHouseSitWizard];
        }
        if (isOwnerFunnelExp) {
            return [MenuItems.meetSitters, MenuItems.findHouses];
        }

        return [MenuItems.variation1SitterHomepage];
    }

    const searchLinksSection = {
        showAt: 0,
        hideAt: theme.screenSize.smallTablet,
        items: getSearchLinksSection(),
    };

    const partialMenuItems = [
        { items: [MenuItems.home] },
        {
            items: [MenuItems.checkout],
        },
        searchLinksSection,
        {
            items: [MenuItems.howItWorks, MenuItems.trustAndSafety, MenuItems.pricing],
        },
        { items: [MenuItems.blog, MenuItems.aboutUs] },
        { items: [helpShort, MenuItems.forum, MenuItems.settings] },
    ];

    const housesitterMenuItems = [
        searchLinksSection,
        {
            items: [
                MenuItems.dashboard,
                MenuItems.yourProfile,
                MenuItems.inbox,
                MenuItems.favorites,
                MenuItems.pastSits,
                MenuItems.settings,
            ],
        },
        { items: [MenuItems.referFriend] },
        {
            items: [MenuItems.forum, MenuItems.findASitter, helpShort],
            removeSectionPartition: true,
        },
    ];

    const homeownerMenuItems = [
        {
            items: [MenuItems.home],
        },
        searchLinksSection,
        {
            items: [
                MenuItems.dashboard,
                MenuItems.yourListing,
                MenuItems.inbox,
                MenuItems.favorites,
                MenuItems.pastSitters,
                MenuItems.settings,
            ],
        },
        { items: [MenuItems.referFriend] },
        { items: [MenuItems.forum, helpShort], removeSectionPartition: true },
    ];

    const homeownerMenuExtraItems = [
        {
            items: [MenuItems.home],
        },
        searchLinksSection,
        {
            items: [
                MenuItems.dashboard,
                MenuItems.yourListing,
                MenuItems.inbox,
                MenuItems.favorites,
                MenuItems.pastSitters,
                MenuItems.settings,
            ],
        },
        { items: [MenuItems.referFriend] },
        { items: [MenuItems.forum, helpShort], removeSectionPartition: true },
    ];

    const combinedMenuItems = [
        {
            items: [MenuItems.home],
        },
        searchLinksSection,
        {
            items: [
                MenuItems.dashboard,
                MenuItems.yourListing,
                MenuItems.yourProfile,
                MenuItems.inbox,
                MenuItems.favorites,
                MenuItems.pastSits,
                MenuItems.pastSitters,
                MenuItems.settings,
            ],
        },
        { items: [MenuItems.referFriend] },
        { items: [MenuItems.forum, helpShort], removeSectionPartition: true },
    ];

    const combinedMenuExtraItems = [
        {
            items: [MenuItems.home],
        },
        searchLinksSection,
        {
            items: [
                MenuItems.dashboard,
                MenuItems.yourListing,
                MenuItems.yourProfile,
                MenuItems.inbox,
                MenuItems.favorites,
                MenuItems.pastSits,
                MenuItems.pastSitters,
                MenuItems.settings,
            ],
        },
        { items: [MenuItems.referFriend] },
        { items: [MenuItems.forum, helpShort], removeSectionPartition: true },
    ];

    const MobileMenuItems = {
        partial: {
            housesitter: partialMenuItems,
            homeowner: partialMenuItems,
            combined: partialMenuItems,
        },
        member: {
            housesitter: {
                basic: housesitterMenuItems,
                classic: housesitterMenuItems,
                premium: housesitterMenuItems,
                standard: housesitterMenuItems,
            },
            homeowner: {
                basic: homeownerMenuItems,
                classic: homeownerMenuItems,
                premium: homeownerMenuExtraItems,
                standard: homeownerMenuExtraItems,
            },
            combined: {
                basic: combinedMenuItems,
                classic: combinedMenuItems,
                premium: combinedMenuExtraItems,
                standard: combinedMenuExtraItems,
            },
        },
        expired: {
            housesitter: [
                {
                    items: [MenuItems.home],
                },
                {
                    items: [{ ...MenuItems.renew, accentLink: true }],
                },
                searchLinksSection,
                {
                    items: [helpShort, MenuItems.forum, MenuItems.settings],
                },
            ],
            homeowner: [
                {
                    items: [MenuItems.home],
                },
                {
                    items: [{ ...MenuItems.renew, accentLink: true }],
                },
                searchLinksSection,
                {
                    items: [helpShort, MenuItems.forum, MenuItems.settings],
                },
            ],
            combined: [
                {
                    items: [MenuItems.home],
                },
                {
                    items: [{ ...MenuItems.renew, accentLink: true }],
                },
                searchLinksSection,
                {
                    items: [helpShort, MenuItems.forum, MenuItems.settings],
                },
            ],
        },
    };

    const partialMenuItemsMobile = [
        { items: [MenuItems.home] },
        searchLinksSection,
        {
            items: [MenuItems.howItWorks, MenuItems.trustAndSafety, MenuItems.pricingPlans],
        },
        { items: [MenuItems.blog, MenuItems.aboutUs] },
        { items: [helpShort, MenuItems.forum, MenuItems.settings] },
    ];

    const MobileMenuItemsList = {
        ...MobileMenuItems,
        partial: {
            housesitter: partialMenuItemsMobile,
            homeowner: partialMenuItemsMobile,
            combined: partialMenuItemsMobile,
        },
    };

    const AnonymousMobileMenu = [
        { items: [MenuItems.home] },
        {
            items: [{ ...MenuItems.login, accentLink: true }],
        },
        searchLinksSection,
        {
            items: [MenuItems.howItWorks, MenuItems.trustAndSafety, MenuItems.pricingPlans],
        },
        { items: [MenuItems.blog, MenuItems.aboutUs] },
        { items: [helpShort, MenuItems.forum] },
    ];

    if (isAnonymous) {
        return AnonymousMobileMenu;
    }
    return MobileMenuItemsList;
};

export { getMobileMenuVariation1 };

/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

const Logo = styled.div(
    ({ theme: { spacing } }) => css`
        margin-right: ${spacing[2]};
    `
);

export const MobileLogoStyled = styled(Logo)(
    ({ theme: { screenSize } }) => css`
        display: block;

        @media only screen and (min-width: ${screenSize.largeDesktop}px) {
            display: none;
        }

        & svg {
            width: auto;
            height: 24px;

            @media only screen and (min-width: ${screenSize.largeHandset}px) {
                height: 28px;
            }

            @media only screen and (min-width: ${screenSize.smallTablet}px) {
                // Use the SVG dimensions
                width: unset;
                height: unset;
            }
        }
    `
);

export const DesktopLogoStyled = styled(Logo)(
    ({ theme: { screenSize } }) => css`
        display: none;

        @media only screen and (min-width: ${screenSize.largeDesktop}px) {
            display: block;
        }
    `
);

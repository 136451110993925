/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { ThemeContext } from 'styled-components';
import { addEventListener } from 'utils/funcs';
import { MembershipStatus } from 'api/types';
import ZIndexManager from 'components/ZIndexManager';
import {
    AvatarStyled,
    WrapperButtonStyled,
    NameStyled,
    MenuWrapperStyled,
    WrapperStyled,
    Chevron,
} from './AccountMenu.style';
import { addUpgradeCTA, AccountMenuItems } from './AccountMenu.config';
import { AccountMenuItemsVariation1 } from './AccountMenuVariation1.config';
import { AccountMenuItemsNoFavouritesVariation } from './AccountMenuNoFavouritesVariation.config';
import Menu from '../Menu';
import Logout from '../Logout';

export const MenuState = {
    OPEN: 'open',
    CLOSED: 'closed',
};

class AccountMenu extends Component {
    menuItems;

    shared;

    globalClickEvent;

    globalFocusEvent;

    wrapperRef;

    static contextType = ThemeContext;

    static defaultProps = {
        menuState: MenuState.CLOSED,
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.handleExternalEvent = this.handleExternalEvent.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    state = {
        menuState: MenuState.CLOSED,
    };

    componentDidMount() {
        this.globalClickEvent = addEventListener(document, 'click', this.handleExternalEvent);
        this.globalFocusEvent = addEventListener(document, 'focusin', this.handleExternalEvent);
    }

    componentWillUnmount() {
        this.globalClickEvent.remove();
        this.globalFocusEvent.remove();
    }

    onClick() {
        this.toggleMenu();
    }

    handleExternalEvent(event) {
        if (
            this.state.menuState === MenuState.OPEN &&
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target)
        ) {
            this.closeMenu();
        }
    }

    toggleMenu() {
        this.setState(({ menuState }) => ({
            menuState: menuState === MenuState.OPEN ? MenuState.CLOSED : MenuState.OPEN,
        }));
    }

    closeMenu() {
        this.setState({
            menuState: MenuState.CLOSED,
        });
    }

    render() {
        const {
            userName,
            avatarPublicId,
            className,
            membershipTier,
            membershipType,
            membershipStatus,
            t,
            isLoggedIn,
            isSitterHomepageVariation,
            isSitterOnly,
            isPaidMember,
            shouldHideFavouritesTest,
            showUpgradeCTA,
        } = this.props;
        const { menuState } = this.state;

        // we show different links in the navbar depending on
        // the user's member status, type and tier. Here we decide which
        // links to get depending on the aforementioned criteria.
        let AccountMenuItemsList = AccountMenuItems;
        if (isSitterHomepageVariation && isSitterOnly && isPaidMember) {
            AccountMenuItemsList = AccountMenuItemsVariation1;
        }

        if (shouldHideFavouritesTest) {
            AccountMenuItemsList = AccountMenuItemsNoFavouritesVariation;
        }

        const isMember = membershipStatus === MembershipStatus.MEMBER;

        let filteredMenuItems = isMember
            ? AccountMenuItemsList[membershipStatus][membershipType][membershipTier]
            : AccountMenuItemsList[membershipStatus][membershipType];

        if (showUpgradeCTA && isMember) {
            filteredMenuItems = addUpgradeCTA({ items: filteredMenuItems, theme: this.context });
        }

        return (
            <WrapperStyled
                ref={(ref) => {
                    this.wrapperRef = ref;
                }}
                className={className}
            >
                <WrapperButtonStyled data-testid="menu-button-wrapper" onClick={this.onClick}>
                    <AvatarStyled publicId={avatarPublicId} size="small" />
                    <NameStyled>{userName}</NameStyled>
                    <Chevron open={menuState === MenuState.OPEN} />
                </WrapperButtonStyled>
                <ZIndexManager layer="topnav">
                    <MenuWrapperStyled
                        data-testid="account-menu-wrapper"
                        menuOpen={menuState === MenuState.OPEN}
                    >
                        <Menu
                            onCloseHandler={this.closeMenu}
                            menuItems={filteredMenuItems}
                            t={t}
                            data-testid="account-menu"
                        />

                        {isLoggedIn ? <Logout onClick={this.onClick} t={t} /> : null}
                    </MenuWrapperStyled>
                </ZIndexManager>
            </WrapperStyled>
        );
    }
}

export default AccountMenu;

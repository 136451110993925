import styled from 'styled-components';
import { zIndex } from 'components/ZIndexManager';
import { IconChevronDown } from 'components/Icon';
import { colors } from 'theme';
import UserAvatar from 'components/UserAvatar';

const WrapperButtonStyled = styled.button`
    background: transparent;
    border: 0;

    cursor: pointer;
    height: 100%;

    display: flex;
    align-items: center;
    padding: 0 16px;

    transition: background-color 150ms ease-in-out;

    &:hover {
        background-color: ${colors.white.modifiers.hover.value};
    }
`;

const AvatarStyled = styled(UserAvatar)`
    margin-right: ${({ theme }) => theme.spacing[2]};
`;

const NameStyled = styled.span`
    margin-right: ${({ theme }) => theme.spacing[2]};
`;

const MenuWrapperStyled = styled.div`
    z-index: ${zIndex};
    min-width: 270px;
    display: ${({ menuOpen }) => (menuOpen ? 'inline-block' : 'none')};
    position: absolute;
    top: 84px;
    right: 20px;

    background: white;
    border: 1px solid ${({ theme }) => theme.palette.border};
    padding: ${({ theme }) => `0 ${theme.spacing[1.5]}`};
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.08);

    white-space: nowrap;

    &:after {
        position: absolute;
        right: 0;
        top: -8px;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 8px 13px;
        border-color: transparent transparent white transparent;
    }

    &:before {
        position: absolute;
        right: -1px;
        top: -10px;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 10px 16px;
        border-color: transparent transparent ${({ theme }) => theme.palette.border} transparent;
    }
`;

const WrapperStyled = styled.div`
    position: relative;
    display: inline-block;
`;

const Chevron = styled(IconChevronDown).attrs({
    size: 'medium',
})`
    transform: rotateX(${({ open }) => (open ? '180deg' : 0)});
`;

export { WrapperButtonStyled, AvatarStyled, NameStyled, MenuWrapperStyled, WrapperStyled, Chevron };

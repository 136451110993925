import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getAccount, getAccountCurrentMembershipPlan } from 'api/selectors/account';
import {
    getIsMembershipActive,
    getMembershipStatus,
    isSitterOnly as getIsSitterOnly,
} from 'utils/account';
import Navbar from 'components/Navbar';
import { getIsLoggedIn, getStoredPromo } from 'shared/selectors';
import { getExperimentalFeatureVariationSelector } from 'containers/ExperimentalFeature/selectors';
import { experiments, userTypes, VariationTypes } from 'containers/ExperimentalFeature';

const Nav = ({
    isLoggedIn,
    account,
    promoCode,
    isOwnerFunnelExp,
    sitterHomepageVariation,
    isPaidMember,
    isSitterOnly,
    shouldFireAnalyticsForSitterHomepage,
    options = {},
    isNoWizardNavVariation,
}) => {
    const avatarPublicId = account.details.profilePhoto
        ? account.details.profilePhoto.publicId
        : null;

    return (
        <Navbar
            isLoggedIn={isLoggedIn}
            membershipStatus={isLoggedIn ? getMembershipStatus(account.membershipPlan) : null}
            membershipTier={isLoggedIn ? account.membershipPlan.tier : null}
            membershipType={isLoggedIn ? account.membershipPlan.membershipType : null}
            avatarPublicId={isLoggedIn ? avatarPublicId : null}
            isOnFreeTrial={isLoggedIn ? account.membershipPlan.isOnFreeTrial : null}
            userName={isLoggedIn ? account.settings.firstName : null}
            promoCode={promoCode}
            isOwnerFunnelExp={isOwnerFunnelExp}
            sitterHomepageVariation={sitterHomepageVariation}
            isSitterOnly={isSitterOnly}
            isPaidMember={isPaidMember}
            options={options}
            shouldFireAnalyticsForSitterHomepage={shouldFireAnalyticsForSitterHomepage}
            isNoWizardNavVariation={isNoWizardNavVariation}
        />
    );
};

const mapStateToProps = (state) => {
    const {
        variation: sitterHomepageVariation,
        enabled: sitterHomepageEnabled,
        fullRollout: sitterHomepageFullRollout,
    } = getExperimentalFeatureVariationSelector(state, {
        experiment: experiments.SITTER_HOMEPAGE,
    });

    const membershipPlan = getAccountCurrentMembershipPlan(state);
    const shouldFireAnalyticsForSitterHomepage =
        sitterHomepageEnabled && !sitterHomepageFullRollout;

    const { variation: noWizardNavVariation } = getExperimentalFeatureVariationSelector(state, {
        experiment: experiments.NO_WIZARD_NAV,
        excludeCombo: [userTypes.PaidUser, userTypes.ExpiredUser, userTypes.PartialUser],
    });
    const isNoWizardNavVariation = noWizardNavVariation === VariationTypes.VARIATION1;

    return {
        isLoggedIn: getIsLoggedIn(state),
        account: getAccount(state),
        promoCode: getStoredPromo(state),
        isSitterOnly: getIsSitterOnly(membershipPlan),
        isPaidMember: getIsMembershipActive(membershipPlan),
        /*
         * We don't use state.router data but we subscribe to route changes
         * to force nav to re-render and update styles
         */
        router: state.router,
        sitterHomepageVariation,
        shouldFireAnalyticsForSitterHomepage,
        isNoWizardNavVariation,
    };
};

Nav.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    account: PropTypes.objectOf(PropTypes.any).isRequired,
    promoCode: PropTypes.string,
    isOwnerFunnelExp: PropTypes.bool,
    sitterHomepageVariation: PropTypes.string,
    isPaidMember: PropTypes.bool.isRequired,
    isSitterOnly: PropTypes.bool.isRequired,
    shouldFireAnalyticsForSitterHomepage: PropTypes.bool,
    options: PropTypes.objectOf(PropTypes.any),
    isNoWizardNavVariation: PropTypes.bool.isRequired,
};

Nav.defaultProps = {
    promoCode: null,
    isOwnerFunnelExp: false,
    sitterHomepageVariation: null,
    shouldFireAnalyticsForSitterHomepage: false,
    options: {},
};

export default connect(mapStateToProps, {})(Nav);

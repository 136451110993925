import { connect } from 'react-redux';

import * as actions from './actions';

const LogoutContainer = ({ children, logout }) => children({ logout });

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(actions.logout.create());
    },
});

export default connect(null, mapDispatchToProps)(LogoutContainer);

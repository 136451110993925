import React, { Fragment } from 'react';
import { SVGComponent as BrandRefreshLogo } from 'components/Logo/brandRefreshLogo.svg';
import { SVGComponent as BrandRefreshLogoFull } from 'components/Logo/brandRefreshLogoFull.svg';
import { MobileLogoStyled, DesktopLogoStyled } from './Logo.style';

const Logout = () => (
    <>
        <MobileLogoStyled>
            <BrandRefreshLogo />
        </MobileLogoStyled>
        <DesktopLogoStyled>
            <BrandRefreshLogoFull />
        </DesktopLogoStyled>
    </>
);

export default Logout;

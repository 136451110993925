import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { zIndex } from 'components/ZIndexManager';
import { Button, ButtonIcon } from 'components/buttons';
import NavLink from 'components/NavLink';
import AccountMenu from './components/AccountMenu';

const fixedStyles = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
`;

export const Container = styled.div`
    ${({ menuOpen }) => (menuOpen ? fixedStyles : null)};
    z-index: ${zIndex};
    background-color: ${({ theme }) => theme.palette.white};
    display: flex;
    justify-content: space-between;

    padding: 0;
    padding-left: ${({ theme }) => theme.spacing[2]};
    /* Height is set in rem to scale with the users font size. */
    height: 3rem;

    ${({ theme }) => `border-bottom: 1px solid ${theme.palette.border};`}

    @media screen and (min-width: ${({ theme }) => `${theme.screenSize.largeHandset}px`}) {
        height: 4rem;
    }

    @media (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        padding-right: ${({ theme }) => theme.spacing[2]};
    }

    @media (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        padding-right: ${({ theme }) => theme.spacing[3]};
        padding-left: ${({ theme }) => theme.spacing[3]};
    }
`;

export const Left = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;

    align-self: flex-start;

    height: 100%;
`;

export const Right = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-end;
    flex-shrink: 1;

    height: 100%;
`;

export const MenuButton = styled(ButtonIcon).attrs({
    variant: ButtonIcon.Variant.FLAT,
})`
    padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[2]}`};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        display: none;
    }

    ${({ isMobileHidden }) =>
        isMobileHidden &&
        css`
            display: none;
        `};
`;

export const MobileMenuWrapper = styled.div`
    z-index: ${zIndex};
    /*
    * The vertical padding here is set in rem to match the height of the navbar
    */
    padding: ${({ theme }) => `3rem ${theme.spacing[1.5]}`};
    ${({ menuOpen }) => (menuOpen ? null : 'display: none')};

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;

    background-color: ${({ theme }) => theme.palette.white};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeHandset}px) {
        padding: ${({ theme }) => `4rem ${theme.spacing[3]}`};
    }

    @media (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        display: none;
    }
`;

export const LogoLinkStyled = styled(Link)`
    display: flex;
    align-items: center;
    line-height: 1;
`;

export const AccountMenuStyled = styled(AccountMenu)`
    height: 100%;
    display: none;
    @media (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        display: inline-block;
    }
`;

export const LeftHandSideNavLinkStyled = styled(NavLink)(
    ({ theme: { screenSize, fontSizes } }) => css`
        font-size: 0.875rem; // 14px

        @media screen and (min-width: ${screenSize.smallDesktop}px) {
            font-size: ${fontSizes.medium};
        }
    `
);

export const navLinkCTA = css`
    display: flex;
    margin-right: ${({ theme }) => theme.spacing[0.5]};
    margin-left: ${({ theme }) => theme.spacing[1.5]};
    padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[1.5]}`};
    font-size: 0.875rem; // 14px

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[3]}`};
        font-size: ${({ theme }) => theme.fontSizes.medium};
    }

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        margin-right: ${({ theme }) => theme.spacing[0]};
    }
`;

export const NavLinkJoinNowStyled = styled(NavLink)`
    ${navLinkCTA};
`;

export const NavLinkJoinNowDesktopStyled = styled(NavLink)`
    ${navLinkCTA};
    display: none;

    @media only screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        display: inline;
    }
`;

export const NavLinkJoinNowMobileStyled = styled(NavLink)`
    ${navLinkCTA};

    @media (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        display: none;
    }
`;

export const JoinNowButtonStyled = styled(Button).attrs({
    variant: Button.Variant.CTA,
})`
    ${navLinkCTA};

    ${({ isMobileHidden }) =>
        isMobileHidden &&
        css`
            display: none;

            @media (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
                display: block;
            }
        `};
`;

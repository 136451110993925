import React from 'react';
import { Button } from 'components/buttons';
import LogoutContainer from 'containers/Logout';

import { MenuSectionStyled, MenuLinkStyled } from '../MenuComponents';

const Logout = ({ onClick, t }) => (
    <MenuSectionStyled>
        <LogoutContainer>
            {({ logout }) => (
                <MenuLinkStyled
                    onClick={() => {
                        onClick();
                        logout();
                    }}
                    tag="button"
                    key="logout"
                    variant={Button.Variant.LINK}
                >
                    {t('navigation_account_logOut')}
                </MenuLinkStyled>
            )}
        </LogoutContainer>
    </MenuSectionStyled>
);

export default Logout;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { MembershipStatus } from 'api/types';
import { experiments, VariationTypes } from 'containers/ExperimentalFeature';
import useHideFavouritesCheck from 'hooks/useHideFavouritesCheck';
import { useExperimentalFeature } from 'hooks/useExperimentalFeature';
import { useSelector } from 'react-redux';
import { getAcqExperiments } from 'src/shared/acqCampaign';
import { isUserInAcqCampaign } from 'src/shared/acqCampaign/utils';
import { WrapperStyled } from './NavMenu.style';
import Menu from '../Menu';
import Logout from '../Logout';
import { getMobileMenu } from './NavMenu.config';
import { getMobileMenuVariation1 } from './NavMenuVariation1.config';
import { getMobileMenuNoFavouritesVariation } from './NavMenuNoFavouritesVariation.config';

const NavMenu = ({
    className,
    isLoggedIn,
    membershipTier,
    membershipType,
    membershipStatus,
    onCloseHandler,
    isOwnerFunnelExp,
    isSitterHomepageVariation,
    isSitterOnly,
    isPaidMember,
    isNoWizardNavVariation,
    showUpgradeCTA,
}) => {
    const { t } = useTranslation();
    const acqCampaignTests = useSelector(getAcqExperiments);

    const theme = useContext(ThemeContext);
    const [homePageCTAVariation] = useExperimentalFeature(experiments.HOMEPAGE_CTA);
    const [noWizardNavbarV2Variant] = useExperimentalFeature(experiments.NO_WIZARD_NAVBAR_V2, {
        hasPaidTestConflict: isUserInAcqCampaign(acqCampaignTests),
    });
    const AnonymousMobileMenu = getMobileMenu({
        isAnonymous: true,
        theme,
        isOnHomepageCTAVariation: homePageCTAVariation !== VariationTypes.CONTROL,
        isNoWizardNavVariation,
        isNoWizardNavbarV2Variation: noWizardNavbarV2Variant === VariationTypes.VARIATION1,
        showUpgradeCTA,
    });

    const shouldHideFavouritesTest = useHideFavouritesCheck();

    let MobileMenuItemsList = getMobileMenu({
        theme,
        showUpgradeCTA,
        isOnHomepageCTAVariation: homePageCTAVariation !== VariationTypes.CONTROL,
    });

    if (isSitterHomepageVariation && isSitterOnly && isPaidMember) {
        MobileMenuItemsList = getMobileMenuVariation1({ theme });
    }

    if (shouldHideFavouritesTest) {
        MobileMenuItemsList = getMobileMenuNoFavouritesVariation({ theme, showUpgradeCTA });
    }

    // we show different links in the navbar depending on
    // the user's member status, type and tier. Here we decide which
    // links to get depending on the aforementioned criteria.
    let mobileMenuItems = [];

    if (isOwnerFunnelExp) {
        const menuList = getMobileMenu({
            isAnonymous: false,
            isOwnerFunnelExp,
            theme,
        });

        if (isLoggedIn) {
            mobileMenuItems =
                membershipStatus === MembershipStatus.MEMBER
                    ? menuList[membershipStatus][membershipType][membershipTier]
                    : menuList[membershipStatus][membershipType];
        } else {
            mobileMenuItems = getMobileMenu({
                isAnonymous: true,
                isOwnerFunnelExp,
                theme,
            });
        }
    } else if (isLoggedIn) {
        mobileMenuItems =
            membershipStatus === MembershipStatus.MEMBER
                ? MobileMenuItemsList[membershipStatus][membershipType][membershipTier]
                : MobileMenuItemsList[membershipStatus][membershipType];
    } else {
        mobileMenuItems = AnonymousMobileMenu;
    }

    return (
        <WrapperStyled className={className}>
            <Menu
                onCloseHandler={onCloseHandler}
                t={t}
                menuItems={mobileMenuItems}
                isLoggedIn={isLoggedIn}
                data-testid="mobile-menu"
            />
            {isLoggedIn ? <Logout onClick={onCloseHandler} t={t} /> : null}
        </WrapperStyled>
    );
};

NavMenu.defaultProps = {};

export default NavMenu;

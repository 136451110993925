/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const WrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background-color: ${({ theme }) => theme.palette.white};
`;

export { WrapperStyled };
